import React from "react";
import ReactDOM from "react-dom/client";

import { configureStore } from "@reduxjs/toolkit";
import App from "./components/App";
import { Provider } from "react-redux";
import appReducer from "./store";
import { dashboard } from "./store/queries/Dashboard";
import { pendingRequests } from "./store/queries/pendingRequests";
import { approvedRequests } from "./store/queries/approvedRequests";
import { payouts } from "./store/queries/payouts";
import { priceUpdation } from "./store/queries/priceUpdation";
import { userVerification } from "./store/queries/userVerification";

const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      dashboard.middleware,
      pendingRequests.middleware,
      approvedRequests.middleware,
      payouts.middleware,
      priceUpdation.middleware,
      userVerification.middleware,
    ),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
