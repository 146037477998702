import DashboardHero from "../DashboardHero";
import useDashboardListing from "./useDashboardListing";
import Style from "./dashboardListing.module.scss";
import EmptyData from "../../Global/EmptyData";
import { DataContainer } from "@wac-ui-dashboard/wac_component_library";
import Charts from "../../Global/Charts";

const DashboardListing = () => {
  const {
    dashboardItems,
    dashboardData,
    isFetching,
    children,
    listingType,
    lineData,
    doughnutData,
    doughnutOptions,
    lineDataOptions,
  } = useDashboardListing();
  return (
    <div className={Style.dashboard_wrap}>
      <div className={`pro-w-100 pro-my-5`}>
        <DashboardHero
          data={dashboardData?.data?.cards[0]}
          obj={dashboardItems}
          loading={isFetching}
          listingType={listingType}
        />
      </div>
      <div className="row gx-5 pro-mb-5">
        <div className="col-lg-8">
          <div className={`${Style.graph_box}`}>
            <DataContainer>
              <h6 className="pro-ttl h6">Approved Requests</h6>
              <Charts
                type="line"
                labels={lineData?.labels}
                datasets={lineData?.datasets}
                options={lineDataOptions}
              />
            </DataContainer>
          </div>
        </div>

        <div className="col-lg-4">
          <div className={`${Style.graph_box}`}>
            <DataContainer>
              <h6 className="pro-ttl h6">Payout Status</h6>
              <Charts
                type="doughnut"
                labels={doughnutData?.labels}
                datasets={doughnutData?.datasets}
                options={doughnutOptions}
              />
            </DataContainer>
          </div>
        </div>
      </div>
      {dashboardData?.data && Object.keys(dashboardData?.data)?.length !== 0 ? (
        children
      ) : (
        <>
          <EmptyData />
        </>
      )}
    </div>
  );
};

export default DashboardListing;
