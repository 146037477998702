import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();

    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("user_id")}&${getParams("price")}${getParams(
          "start_date"
        )}&${getParams("end_date")}${getParams("search")}&${getParams(
          "sort_by"
        )}&sort=${params?.sort_order || "desc"}&page_size=${
          params?.page_size || "10"
        }&page_no=${params?.page_no || 1}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const priceUpdation = createApi({
  reducerPath: "PriceUpdationApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["PriceUpdation"],
  endpoints: (builder) => ({
    getPriceUpdationData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `vendor/api/reward`,
      }),
      providesTags: ["PriceUpdation"],
      keepUnusedDataFor: 0,
    }),
    updatePrice: builder.mutation({
      query: (values) => ({
        method: "put",
        params: values.price,
        endpoint: `vendor/api/reward/${values.id}`,
      }),
      invalidatesTags: ["PriceUpdation"],
    }),
    updateTableFieldsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `vendor/api/panel-listing`,
      }),
      invalidatesTags: ["PriceUpdation"],
    }),
  }),
});

export const {
  useGetPriceUpdationDataQuery,
  useUpdatePriceMutation,
  useUpdateTableFieldsDataMutation,
} = priceUpdation;
