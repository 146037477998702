import { createSlice } from "@reduxjs/toolkit";
import { subDays } from "date-fns";

const initialState = {
     //   menuDrawer: {},
     //   isProfileViewVisible:false,
     //   showEditModal: false,
     //   isLogged: false,
     //   currentPage: 1,
     //   otpGenerated: "",
     //   tableFields: {},
     //   searchData: {},
     //   searchStatus: "idle",
     //   searchKey: "",
     //   drawerTheme: "expanded",
     //   currentTheme: "light",
     //   dashboard_permissions: {},
     //   dashboard_permissions_status: "idle",
     currentPage: 1,
     currentPageSize: 10,
     showCreateModal:false,
     selectedItemDetails:{},
     start: subDays(new Date(), 30),
     end: new Date(),
     sortBy: "",
     sortOrder: "desc",
};





const PriceUpdationSlice = createSlice({
     name: "priceUpdation",
     initialState,
     reducers: {
          updateConfig: (state, action) => {
               action.payload(state);
          },
     },

});

export const { updateConfig } = PriceUpdationSlice.actions;

export default PriceUpdationSlice.reducer;
