import { getAxiosInstance } from "../../api";

export const login = async (data) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("vendor/api/login", data);

    return response;
  } catch (error) {
    return error.response;
  }
};

export const forgotPassword = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("v1/auth/forgot-password", params);

    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const resetPassword = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      `/v1/auth/forgot-password-change/${params.token}`,
      params
    );

    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const validateEmail = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("/vendor/api/forgot-password", params);

    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const changePassword = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/vendor/api/reset-password`, body);

    return response;
  } catch (error) {
    return error.response.data;
  }
};
