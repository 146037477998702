import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useGetUserDetailsQuery } from "../../../store/queries/userVerification";
import { getOfferPoints, checkUserPoint } from "../api";
import { toast } from "react-toastify";
import { getOfferDetails } from "../../../store/slices/UserVerification/UserVerificationSlice";

const useOfferVerificationMain = () => {
  const dispatch = useDispatch();
  const [showOfferOtp, setShowOfferOtp] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [verifyVendorOtpModal, setVerifyVendorOtpModal] = useState(false);
  const [redeemPoints, setRedeemPoints] = useState("");
  const { selectedUserDetails } = useSelector(
    (state) => state.userVerification
  );
  const User_Details = localStorage.getItem("USER_DETAILS");

  const {
    data: userData = {},
    // isFetching,
  } = useGetUserDetailsQuery(selectedUserDetails[0]?.id ?? User_Details?.id);

  const validation = Yup.object({
    offer: Yup.number().required("offer is  required"),
    entered_point: Yup.number()
      .required("Required ")
      .min(1, "minimum value must be 1")
      .test(
        "is-smaller-or-equal",
        "Entered Point must be smaller or equal to Balance Points",
        function (value) {
          const balancePoints = formik?.values?.balance_points;
          return value <= balancePoints;
        }
      ),
  });

  const formik = useFormik({
    initialValues: {
      offer: "",
      series: "",
      total_point: "",
      balance_points: "",
      entered_point: "",
    },
    validationSchema: validation,
    // enableReinitialize: true,
    onSubmit: (values) => {
      const obj = {
        user_id: selectedUserDetails[0].id ?? User_Details?.id,
        offer_id: values?.offer,
        total_points: values?.total_point,
        entered_point: values?.entered_point,
      };
      checkUserPoint(obj).then((response) => {
        if (response?.data?.statusCode === 200) {
          toast.success(response?.data?.message);
          setShowOfferOtp(true);
        } else {
          toast.error(response?.message ?? response?.data?.message);
        }
      });
    },
  });

  useEffect(() => {
    if (formik?.values?.offer) {
      handleGetOfferPoints();
    }
    //eslint-disable-next-line
  }, [formik?.values?.offer]);

  const handleGetOfferPoints = () => {
    const obj = {
      offer_id: formik?.values?.offer ?? null,
    };
    getOfferPoints(obj).then((response) => {
      if (response?.data?.statusCode === 200) {
        toast.success(response?.data?.message);

        formik?.setFieldValue(
          "total_point",
          response?.data?.data?.total_points
        );
        formik?.setFieldValue("balance_points", response?.data?.data?.points);
      } else {
        toast.error(response?.data?.message);
      }
    });
  };

  const handleChangeOffer = (value) => {
    formik.setFieldValue("offer", value?.offer_id || null);
    // const seriesChangeTimeOut = setTimeout(() => {
    //   handleGetOfferPoints(value);
    //   clearTimeout(seriesChangeTimeOut);
    // }, 1000);
  };

  const handleSendOfferOtp = () => {
    const obj = {
      // user_id: selectedUserDetails[0].id ?? User_Details?.id,
      offer_id: formik?.values?.offer,
      // series_id: formik?.values?.series,
      // total_points: formik?.values?.total_point,
      entered_point: formik?.values?.entered_point,
    };
    dispatch(getOfferDetails(obj)).then((res) => {
      if (res?.payload?.statusCode === 200) {
        toast.success(res?.payload?.message);
        setVerifyVendorOtpModal(true);
        setSeconds(60);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const setCloseVerifyVendorOtpModal = () => {
    setVerifyVendorOtpModal(false);
  };

  return {
    handleChangeOffer,
    setRedeemPoints,
    handleSendOfferOtp,
    setCloseVerifyVendorOtpModal,
    setSeconds,
    verifyVendorOtpModal,
    selectedUserDetails,
    formik,
    userData,
    redeemPoints,
    showOfferOtp,
    seconds,
  };
};

export default useOfferVerificationMain;
