import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  menuDrawer: {},
  isProfileViewVisible:false,
  showEditModal: false,
  isLogged: false,
  currentPage: 1,
  otpGenerated: "",
  tableFields: {},
  searchData: {},
  searchStatus: "idle",
  searchKey: "",
  drawerTheme: "expanded",
  currentTheme: "light",
  dashboard_permissions: {},
  dashboard_permissions_status: "idle",
};

export const getMenuPermissions = createAsyncThunk(
  "global/getMenuPermissions",
  async (body, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/all-menu`, body);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserDetails = createAsyncThunk(
  "user/getUserDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `v1/users/list-all-users?search=${params?.searchKey}&length=20&page=${params?.page}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const globalSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMenuPermissions.pending, (state) => {
      state.dashboard_permissions_status = "pending";
    });
    builder.addCase(getMenuPermissions.fulfilled, (state, action) => {
      state.dashboard_permissions = action.payload.data.data;
      state.dashboard_permissions_status = "fulfilled";
      localStorage.setItem(
        "dashboard_permissions",
        JSON.stringify(action.payload.data.data)
      );
    });
    builder.addCase(getMenuPermissions.rejected, (state) => {
      state.dashboard_permissions_status = "rejected";
    });

    builder.addCase(getUserDetails.pending, (state, action) => {
      state.searchStatus = "pending";
    });
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      if (
        state.searchData?.[action.meta?.arg?.user_id] &&
        !action.meta?.arg?.clearState
      ) {
        if (action.payload?.data?.data?.rows?.data) {
          state.searchData[action.meta?.arg?.user_id] = {
            ...action.payload?.data?.data?.rows,
            searchStatus: "fulfilled",
            data: [
              ...state.searchData[action.meta?.arg?.user_id].data,
              ...action.payload?.data?.data?.rows?.data,
            ],
          };
        } else {
          state.searchData[action.meta?.arg?.user_id] = {
            ...action.payload?.data?.data?.rows,
            searchStatus: "fulfilled",
            data: [...state.searchData[action.meta?.arg?.user_id].data],
          };
        }
      } else {
        state.searchData[action.meta?.arg?.user_id] = {
          ...action.payload?.data?.data?.rows,
          searchStatus: "fulfilled",
          page: 1,
        };
      }
      state.searchStatus = "fulfilled";
    });
    builder.addCase(getUserDetails.rejected, (state, action) => {
      state.searchStatus = "failed";
    });

    // builder.addCase(getNotificationData.pending, (state, action) => {
    //   state.notificationStatus = "pending";
    // });
    // builder.addCase(getNotificationData.fulfilled, (state, action) => {
    //   if (
    //     state.notificationData?.[action.meta?.arg?.user_id] &&
    //     !action.meta?.arg?.clearState
    //   ) {
    //     if (action.payload?.data?.data?.data?.data) {
    //       state.notificationData[action.meta?.arg?.user_id] = {
    //         ...action.payload?.data?.data?.data,
    //         data: [
    //           ...state.notificationData[action.meta?.arg?.user_id].data,
    //           ...action.payload?.data?.data?.data?.data,
    //         ],
    //       };
    //     } else {
    //       state.notificationData[action.meta?.arg?.user_id] = {
    //         ...action.payload?.data?.data?.data,
    //         data: [...state.notificationData[action.meta?.arg?.user_id].data],
    //       };
    //     }
    //   } else {
    //     state.notificationData[action.meta?.arg?.user_id] = {
    //       ...action.payload?.data?.data?.data,
    //       page: 1,
    //     };
    //   }
    //   state.notificationStatus = "fulfilled";
    // });
    // builder.addCase(getNotificationData.rejected, (state, action) => {
    //   state.notificationStatus = "failed";
    // });
  },
});

export const { updateConfig } = globalSlice.actions;

export default globalSlice.reducer;
