import React from "react";
import { Button, Image, Input } from "@wac-ui-dashboard/wac_component_library";
import useUserOtpVerificationModal from "./useUserOtpVerificationModal";
import Assets from "../../../../assets/Assets";
import Style from "../../userVerification.module.scss";

const UserOtpVerificationModal = ({
  handleSubmitOtp,
  seconds,
  setSeconds,
  setVerifyOtpModal,
}) => {
  const { selectedUserDetails, formik } = useUserOtpVerificationModal({
    setSeconds,
    setVerifyOtpModal,
  });

  return (
    <div className={Style.user_modal}>
      <div className="pro-d-flex pro-items-center">
        <Image
          src={Assets.GENDER_IMAGE}
          width={52}
          height={52}
          alt={`gender`}
          propStyle={{
            root: Style.user_image,
          }}
        />
        <div className="pro-ps-5">
          <h5 className="pro-mb-0">
            {`${selectedUserDetails[0]?.first_name} ${
              selectedUserDetails[0]?.last_name ?? ""
            }`}
          </h5>
          <p className="pro-mb-0">{selectedUserDetails[0]?.mobile_number}</p>
        </div>
      </div>
      <div className="pro-py-4 pro-border-top pro-border-bottom pro-mt-5">
        <Input
          label={"Verify OTP*"}
          id="otp"
          name="otp"
          value={formik?.values?.otp}
          onChange={(e) => formik?.setFieldValue("otp", e.target.value)}
          className={`pro-input lg ${
            formik.errors.otp && formik.touched.otp && " error"
          }`}
          type="text"
          placeholder="Enter OTP"
          error={formik.errors.otp && formik.touched.otp}
          errorMessage={formik.errors.otp}
        />
        <div className="pro-d-flex pro-justify-between pro-pt-2">
          <span className="pro-font-sm">
            Time Remaining:<span className="pro-fw-medium"> {seconds} sec</span>
          </span>
          <button
            className="pro-btn-link"
            onClick={(e) => (seconds > 0 ? "" : handleSubmitOtp(e))}
          >
            Resend OTP
          </button>
        </div>
      </div>
      <div className="pro-d-flex pro-justify-end pro-pt-5">
        <Button
          className={`pro-btn-primary`}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Submit{" "}
        </Button>
      </div>
    </div>
  );
};

export default UserOtpVerificationModal;
