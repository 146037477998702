import { useEffect, useState, useMemo } from "react";
import { useGetDashboardDataQuery } from "../../../store/queries/Dashboard";
import PendingRequestListing from "../../PendingRequests/PendingRequestsListing/index";
import PayoutsListing from "../../Payouts/PayoutsListing/index";
import ApprovedRequestsListing from "../../ApprovedRequests/ApprovedRequestsListing/index";

const useDashboardListing = () => {
  const {
    data: dashboardData = {},
    isFetching,
    isSuccess,
  } = useGetDashboardDataQuery({});

  const [listingType, setListingType] = useState();
  useEffect(() => {
    if (isSuccess) {
      setListingType(
        Object.keys(dashboardData?.data?.cards[0] || {})?.[0] ?? ""
      );
    }

    //eslint-disable-next-line
  }, [isSuccess]);

  const handlePendingRequestList = () => {
    setListingType("pending_requests");
  };
  const handleApprovedUsers = () => {
    setListingType("approved_users");
  };
  const handlePayoutsReceived = () => {
    setListingType("payouts_received");
  };

  let object = {
    pending_requests: handlePendingRequestList,
    approved_users: handleApprovedUsers,
    payouts_received: handlePayoutsReceived,
  };

  const handleClick = (label) => {
    object[label]();
  };

  let dashboardItems = {
    pending_requests: {
      label: "pending_requests",
      title: "Pending Requests",
      icon: "person_raised_hand",
      handleClick,
      component: <PendingRequestListing dashboard={true} />,
    },
    approved_users: {
      label: "approved_users",
      title: "Approved Users",
      icon: "person",
      handleClick,
      component: <ApprovedRequestsListing dashboard={true} />,
    },
    payouts_received: {
      label: "payouts_received",
      title: "Payouts Received",
      icon: "savings",
      handleClick,
      component: <PayoutsListing dashboard={true} />,
    },
  };

  const lineData = useMemo(() => {
    return {
      labels: dashboardData?.data?.approved_requests[0]?.labels,
      datasets:
        dashboardData?.data?.approved_requests[0]?.data?.map((item, index) => ({
          label: item.labels,
          data: item.data,
          pointRadius: 0,
          borderColor: "#19703B",
        })) || [],
    };
    //eslint-disable-next-line
  }, [dashboardData?.data?.approved_requests[0]?.data]);

  const lineDataOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    width: 300,
    height: 300,
    aspectRatio: 1,
  };

  const doughnutData = useMemo(() => {
    return {
      labels: dashboardData?.data?.payouts?.map(
        (item) =>
          `${item?.label?.charAt(0).toUpperCase()}${item?.label?.slice(1)} ${
            item?.percent
          }%`
      ),
      datasets:
        dashboardData?.data?.approved_requests[0]?.data?.map((item, index) => ({
          label: "",
          data: dashboardData?.data?.payouts?.map((item) => item?.value),
          pointRadius: 0,
          backgroundColor: ["#0F9A48", "#D3B751", "#CE8B8B"],
        })) || [],
    };
    //eslint-disable-next-line
  }, [dashboardData?.data?.payouts]);

  const doughnutOptions = {
    plugins: {
      legend: {
        display: true,
        position: "right",
        align: "end",
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          font: {
            size: 10,
          },
        },
      },
      afterDraw: () => {
        "";
      },
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    width: 300,
    height: 300,
    aspectRatio: 1,
  };

  return {
    dashboardItems,
    dashboardData,
    isFetching,
    children: dashboardItems[listingType]?.component,
    listingType,
    lineData,
    doughnutData,
    doughnutOptions,
    lineDataOptions,
  };
};

export default useDashboardListing;
