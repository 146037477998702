import React from "react";
import Assets from "../../../assets/Assets";

export const PrivacyHeader = () => {
  return (
    <header class="main-header">
      <div class="container h-100 d-flx flx-vcenter">
        <div class="logo">
          <a href="/">
            <img src={Assets?.PrivacyLogo} alt="Logo" />
          </a>
        </div>
      </div>
      <div class="overlay"></div>
    </header>
  );
};
