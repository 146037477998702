import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { verifyUserOtp } from "../../api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { updateConfig } from "../../../../store/slices/UserVerification/UserVerificationSlice";

const useUserOtpVerificationModal = ({ setSeconds, setVerifyOtpModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedUserDetails } = useSelector(
    (state) => state.userVerification
  );
  const User_Details = localStorage.getItem("USER_DETAILS");
  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => (prevSeconds > 0 ? prevSeconds - 1 : 0));
    }, 1000);

    return () => clearInterval(intervalId);

    // eslint-disable-next-line
  }, []);

  const validation = Yup.object({
    otp: Yup.number()
      .typeError("Please enter a valid OTP")
      .positive("OTP must be a positive number")
      .integer("OTP must be an integer")
      .min(100000, "OTP must be at least 6 digits")
      .max(999999, "OTP must be at most 6 digits")
      .required("OTP is required"),
  });

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    enableReinitialize: true,
    validationSchema: validation,
    onSubmit: (values) => {
      verifyUserOtp({
        user_id: selectedUserDetails[0].id ?? User_Details?.id,
        otp: values.otp,
      }).then((response) => {
        if (response?.data?.statusCode === 200) {
          toast.success(response?.data?.message);
          dispatch(
            updateConfig((state) => {
              state.userOtpVerified = response?.data?.statusCode;
            })
          );
          setVerifyOtpModal(false);
          const timeout = setTimeout(() => {
            navigate("/user-verification/offer-verification");
            clearTimeout(timeout);
          },300);

          // checkIfActiveRoute("/user-verification", true)
        } else {
          toast.error(response?.data?.message ?? "Unable to verify Otp");
        }
      });
    },
  });

  return { selectedUserDetails, formik };
};

export default useUserOtpVerificationModal;
