import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";
import { subDays } from "date-fns";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  showCreateModal: false,
  selectedUserDetails: {},
  userPhoneNumbers: [],
  selectedUserId: "",
  userVerificationOtp: "",
  start: subDays(new Date(), 30),
  end: new Date(),
  offerDetailsLoading: "idle",
  offerDetailsData: {},
  userOtpVerified: "",
  finalSubmitLoading: "idle",
};

export const getOfferDetails = createAsyncThunk(
  "vendor/api/send-verification-otp-vendor",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `vendor/api/send-verification-otp-vendor`,
        params
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const submitUserVerification = createAsyncThunk(
  `vendor/api/user-verification`,
  async ({ data }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("vendor/api/user-verification", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const UserVerificationSlice = createSlice({
  name: "userVerification",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOfferDetails.pending, (state, action) => {
        state.offerDetailsLoading = true;
      })
      .addCase(getOfferDetails.fulfilled, (state, action) => {
        state.offerDetailsLoading = false;
        state.offerDetailsData = action.payload.data;
      })
      .addCase(getOfferDetails.rejected, (state, action) => {
        state.offerDetailsLoading = false;
      })
      .addCase(submitUserVerification.pending, (state, action) => {
        state.finalSubmitLoading = true;
      })
      .addCase(submitUserVerification.fulfilled, (state, action) => {
        state.finalSubmitLoading = false;
        state.offerDetailsData = action.payload.data;
      })
      .addCase(submitUserVerification.rejected, (state, action) => {
        state.finalSubmitLoading = false;
      });
  },
});

export const { updateConfig } = UserVerificationSlice.actions;

export default UserVerificationSlice.reducer;
