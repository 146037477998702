import { getAxiosInstance } from "../../../api";

export const updatePriceData = async (values) => {
     const api = await getAxiosInstance();
     try {
          const priceDetails = {
               price: values.price
          };
          const response = await api.put(`vendor/api/reward/${values.user_id}`, priceDetails);
          return response.data;
     } catch (error) {
          return error?.response?.data;
     }
};