const useRewardSection = ({ formik }) => {
  const handleRemoveField = (index) => {
    const data = formik?.values?.rewards?.filter(
      (_, itemIndex) => itemIndex !== index
    );
    formik.setFieldValue("rewards", data);
  };
  return { handleRemoveField };
};

export default useRewardSection;
