import React from 'react'
import CommonLayout from '../../pages/Layouts/CommonLayout';
import useMenus from '../Global/useMenus';
import { Outlet } from 'react-router-dom';

const PriceUpdation = () => {
     const { drawerMenu } = useMenus();
     return (
          <CommonLayout drawerMenu={drawerMenu}>
               <Outlet />
          </CommonLayout>
     );
}

export default PriceUpdation
