import React from "react";
import Style from "./addStaffForm.module.scss";
import AdminProfileCard from "../../../Global/AdminProfileCard";

const AddFormFields = ({
  formikEditUserData,
  formikEditPassword,
  profilefileInputRef,
  handleProfileImage,
  handlePhoneNumberChange,
  cuntryCodeOptions,
  imagePreview,
  isDataLoading,
  userData,
  setProfileViewVisible,
  showExistingPassword,
  showNewPassword,
  handleShowExistingPassword,
  handleShowNewPassword,
}) => {
  // isDataLoading === "success" ?

  // const [menuIsOpen, setMenuIsOpen] = useState(false);

  // const handleMenuOpen = () => {
  //   setMenuIsOpen(() => true);
  // };

  // const handleMenuClose = () => {
  //   setMenuIsOpen(() => false);
  // };
  return (
    <div className={Style.root}>
      <div className={Style.item}>
        <AdminProfileCard
          userData={userData}
          profilefileInputRef={profilefileInputRef}
          imagePreview={imagePreview}
          handleProfileImage={handleProfileImage}
        />
      </div>
      <div className={Style.item}>
        {userData?.cash_vendor === true && (
          <div className={`${Style.root_item} pro-d-flex pro-flex-column`}>
            <h6 className="pro-ttl h6 pro-mb-0">Cash Vendor</h6>
          </div>
        )}

        <div className={`${Style.root_item} pro-d-flex pro-flex-column`}>
          <h6 className="pro-ttl h6 pro-mb-0">Name</h6>
          <p className={`pro-mb-0`}>{userData?.name}</p>
        </div>

        <div className={`${Style.root_item} pro-d-flex pro-flex-column`}>
          <h6 className="pro-ttl h6 pro-mb-0">Mobile</h6>
          <p className={`pro-mb-0`}>{userData?.mobile}</p>
        </div>

        <div className={`${Style.root_item} pro-d-flex pro-flex-column`}>
          <h6 className="pro-ttl h6 pro-mb-0">Description</h6>
          <p className={`pro-mb-0`}>{userData?.description}</p>
        </div>

        <div className={`${Style.root_item} pro-d-flex pro-flex-column`}>
          <h6 className="pro-ttl h6 pro-mb-0">Email</h6>
          <p className={`pro-mb-0`}>{userData?.email}</p>
        </div>
      </div>
      {/* <div className={Style.item}>
        <h5>Contact info</h5>
        <div className="row">
          <div className="col-12 pro-mb-4">
            <div className="input-wrap">
              <label
                htmlFor="email"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Email
              </label>
              <input
                type="email"
                className={`pro-input lg ${
                  formikEditUserData.errors.email &&
                  formikEditUserData.touched.email &&
                  "error"
                }`}
                id="email"
                name="email"
                {...formikEditUserData.getFieldProps("email")}
              />
              {formikEditUserData.touched.email &&
                formikEditUserData.errors.email && (
                  <span className="error-text">
                    {formikEditUserData.errors.email}
                  </span>
                )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="input-wrap pro-mb-4">
            <div className="input-country-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Phone number*
              </label>
              <div className="input-main-wrap ">
                <div className="code-wrap pro-mb-4 ">
                  <Select
                    id="dial_code"
                    options={cuntryCodeOptions}
                    // getOptionLabel={(option) => `${option.label}`}
                    getOptionValue={(option) => `${option.value}`}
                    onMenuOpen={handleMenuOpen}
                    onMenuClose={handleMenuClose}
                    menuIsOpen={menuIsOpen}
                    formatOptionLabel={({ label, title }) =>
                      menuIsOpen ? title : label
                    }
                    className={` multi-select country-select ${
                      formikEditUserData.touched.dial_code &&
                      formikEditUserData.errors.dial_code &&
                      " error"
                    }`}
                    value={cuntryCodeOptions?.filter(
                      (p) =>
                        p.value?.toString() ===
                        formikEditUserData.values.dial_code?.toString()
                    )}
                    classNamePrefix="pro-input"
                    onBlur={() => formikEditUserData.handleBlur("dial_code")}
                    onChange={(value) =>
                      formikEditUserData.setFieldValue(
                        "dial_code",
                        value?.value
                      )
                    }
                  ></Select>
                </div>
                <input
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  className={`pro-input lg ${
                    formikEditUserData.errors.phoneNumber &&
                    formikEditUserData.touched.phoneNumber &&
                    " error"
                  }`}
                  {...formikEditUserData.getFieldProps("phoneNumber")}
                  error={
                    formikEditUserData.errors.phoneNumber &&
                    formikEditUserData.touched.phoneNumber
                  }
                  errorMessage={formikEditUserData.errors.phoneNumber}
                  // onBlur={() => formikEditUserData.handleBlur("phoneNumber")}
                  // onChange={(e) => handlePhoneNumberChange(e.target.value)}
                  // {...formikEditUserData.getFieldProps("phoneNumber")}
                />
              </div>
              {formikEditUserData.touched.phoneNumber &&
                formikEditUserData.errors.phoneNumber && (
                  <span className="error-text">
                    {formikEditUserData.errors.phoneNumber}
                  </span>
                )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className={`col-12 pro-mt-4 pro-d-flex pro-justify-end`}>
            <Button
              className={"pro-btn-link lg pro-px-5"}
              onClick={() => setProfileViewVisible(true)}
            >
              Cancel
            </Button>

            <Button
              className={"pro-btn-primary lg pro-mx-3"}
              onClick={formikEditUserData.handleSubmit}
              type="submit"
            >
              {true ? (
                <i
                  className="spinner-border spinner-border-sm me-3"
                  role="status"
                  aria-hidden="true"
                ></i>
              ) : (
                ""
              )}
              Update
            </Button>
          </div>
        </div>
      </div> */}

      {/* <div className={Style.item}>
        <h5>Change Password</h5>
        <div className="row">
          <div className="col-12 pro-mb-4">
            <div className="input-wrap p-re">
              <label
                htmlFor="current_password"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Existing password
              </label>
              <input
                type="password"
                className={`pro-input lg ${
                  formikEditPassword.errors.current_password &&
                  formikEditPassword.touched.current_password &&
                  "error"
                }`}
                id="current_password"
                name="current_password"
                {...formikEditPassword.getFieldProps("current_password")}
              />
              {showExistingPassword ? (
                <button
                  className={`hide-icon`}
                  onClick={(e) => handleShowExistingPassword(e)}
                >
                  <span className="material-symbols-outlined">
                    {" "}
                    visibility_off{" "}
                  </span>
                </button>
              ) : (
                <button
                  className={`hide-icon`}
                  onClick={(e) => handleShowExistingPassword(e)}
                >
                  <span className="material-symbols-outlined">
                    {" "}
                    visibility{" "}
                  </span>
                </button>
              )}
              {formikEditPassword.touched.current_password &&
                formikEditPassword.errors.current_password && (
                  <span className="error-text">
                    {formikEditPassword.errors.current_password}
                  </span>
                )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="input-wrap p-re">
              <label
                htmlFor="password"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                New password
              </label>
              <input
                type="password"
                className={`pro-input lg ${
                  formikEditPassword.errors.password &&
                  formikEditPassword.touched.password &&
                  "error"
                }`}
                id="password"
                name="password"
                {...formikEditPassword.getFieldProps("password")}
              />
              {showNewPassword ? (
                <button
                  className={`hide-icon`}
                  onClick={(e) => handleShowNewPassword(e)}
                >
                  <span className="material-symbols-outlined">
                    {" "}
                    visibility_off{" "}
                  </span>
                </button>
              ) : (
                <button
                  className={`hide-icon`}
                  onClick={(e) => handleShowNewPassword(e)}
                >
                  <span className="material-symbols-outlined">
                    {" "}
                    visibility{" "}
                  </span>
                </button>
              )}
              {formikEditPassword.touched.password &&
                formikEditPassword.errors.password && (
                  <span className="error-text">
                    {formikEditPassword.errors.password}
                  </span>
                )}
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
  //  : isDataLoading === "loading" ? (
  //   <div>Loading...</div>
  // ) : (
  //   ""
  // );
};

export default AddFormFields;
