
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
// import { useUpdatePriceMutation } from '../../../../store/queries/priceUpdation'
import { priceUpdation } from "../../../../store/queries/priceUpdation";
import { updateConfig } from "../../../../store/slices/PriceUpdation/PriceUpdationSlice";
import { toast } from "react-toastify";
import { updatePriceData } from "../api";

const usePriceUpdationModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { selectedItemsDetails } = useSelector((state) => state.priceUpdation);


  const validation = Yup.object({
    price: Yup.number(),
  });

  const formik = useFormik({
    initialValues: {
      price: "",
    },

    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      let obj = {
        user_id: selectedItemsDetails?.id,
        price: values?.price,
      };

      updatePriceData(obj).then((response) => {
        if (response?.statusCode === 200) {
          // resetForm();
          dispatch(priceUpdation.util.invalidateTags(["PriceUpdation"]));
          dispatch(
            updateConfig((state) => {
              state.showCreateModal = false;
            })
          );
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );

          toast.success(response?.message);
        } else if (!response?.payload?.status) {
          formik.setErrors(response?.payload?.data);
        } else toast.error(response?.payload?.message);
      });
    },
  });

  // const handleCloseModal = () => {
  //   dispatch(
  //     updateConfig((state) => {
  //       state.showCreateModal = false;
  //     })
  //   );
  // };

  return { formik, selectedItemsDetails };
};

export default usePriceUpdationModal;
