import React from "react";

const PrivacyFooter = () => {
  return (
    <footer class="footer">
      <div class="container">
        <div class="footer_contents">
          <div class="contact-us">
            <h1 class="header">Contact Us</h1>

            <div class="office-contact d-flx">
              <div class="img">
                <div class="icon">
                  <img src="assets/images/add-icon.png" alt="" />
                </div>
              </div>
              <ul>
                <li>
                  Vill: Ahaladipur, P.O. Shyamsundar, P.S Raina , Dist: Purba
                  Bardhaman,Burdwan - 713424, West Bengal, India
                </li>
                <li class="contact-no">
                  Phone :
                  <a href="tel:08037304166" class="contact-no">
                    08037304166
                  </a>
                </li>
              </ul>
            </div>

            <div class="office-contact d-flx">
              <div class="img">
                <div class="icon">
                  <img src="assets/images/name-icon.png" alt="" />
                </div>
              </div>

              <ul class="persnl-contct">
                <li>Mr Hasibur Rahman (AGM)</li>
                <li class="contact-no">
                  Mobile :
                  <a href="tel:+918918259225" class="contact-no">
                    +91 8918259225
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="d-block d-flx footer-row">
            <div class="text">
              BARDHAMAN AGRO PRODUCTS I PRIVATE LIMITED
              <span>All Rights Reserved</span>.
            </div>
            <div class="text d-flx">
              <p>
                <a
                  target="_blank"
                  href="https://www.tradeindia.com/about-us/terms/terms_01.html"
                >
                  Terms of Use
                </a>
              </p>
              <p>
                <a href="/privacypolicy.php">Privacy Policy</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <script type="text/javascript" src="assets/js/main.js"></script> --> */}
    </footer>
  );
};

export default PrivacyFooter;
