import {
     Button,
     Input,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import usePriceUpdationModal from "./usePriceUpdationModal";


const PriceUpdationModal = ({
     refetch,
     closeModal,
}) => {
     const {
          formik,
          selectedItemsDetails
     } = usePriceUpdationModal({ refetch, closeModal });


     return (
          <div className={`pro-w-100 pro-p-5`}>
               <div className="pro-d-flex pro-flex-column pro-mb-5">
                    <label className="pro-mb-1 pro-text-label pro-fw-normal">Gift Name</label>
                    <h6 className="h6 pro-ttl pro-mb-0 pro-fw-normal">{selectedItemsDetails?.reward_name}</h6>
               </div>

               <div className="pro-d-flex pro-flex-column pro-mb-5">
                    <label className="pro-mb-1 pro-text-label pro-fw-normal">Gift Name</label>
                    <h6 className="h6 pro-ttl pro-mb-0 pro-fw-normal">{selectedItemsDetails?.reward_name}</h6>
               </div>
               
               <div className="pro-d-flex pro-flex-column pro-items-start pro-mb-5">
                    <label className="pro-mb-1 pro-text-label pro-fw-normal">Status</label>
                    {
                         selectedItemsDetails.status === "Pending" ?  <span className='pro-badge badge-warning pro-fw-bold'> Pending </span>  : 
                         selectedItemsDetails.status === "Declined" ?  <span className='pro-badge badge-danger pro-fw-bold'>  Declined </span>  :
                         selectedItemsDetails.status === "Success" ?  <span className='pro-badge badge-success pro-fw-bold'> Success  </span> : ""
                    }
               </div>
               <Input
                    label={"Price*"}
                    type="number"
                    id="price"
                    name="price"
                    className={`pro-input lg ${formik?.errors?.price && formik?.touched?.price && "error"
                         }`}
                    {...formik.getFieldProps("price")}
                    onChange={(event) => formik?.handleChange("price")(event.target.value)}
                    error={formik?.errors?.price && formik?.touched?.price}
                    errorMessage={formik?.errors?.price}
               />



               <div
                    className={`col-12 pro-d-flex pro-justify-end`}
               >
                    <Button
                         className={"pro-btn-link lg pro-px-5"}
                         onClick={() => closeModal()}
                    >
                         Cancel
                    </Button>

                    <Button
                         className={`pro-btn-primary lg pro-ms-3 `}
                         type="submit"
                         onClick={formik.handleSubmit}
                         disabled={!formik?.values?.price}
                    >
                         Update
                    </Button>
               </div>
          </div>
     );
};

export default PriceUpdationModal;
