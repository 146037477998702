import { Navigate } from "react-router-dom";


const ProtectRoute = ({ children }) => {
  let auth = localStorage.getItem("USER_ACCESS_TOKEN");

  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   const delay = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 100);

  //   return () => clearTimeout(delay);
  // }, []);

  if (!auth) {
    return <Navigate to={"/login"} />;
  } 

  return children;
};

export default ProtectRoute;
