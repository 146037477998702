import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "../api";
import { toast } from "react-toastify";

const useCaptureEmail = (emailRef) => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState("");

  const [emailCaptureStatus, setEmailCaptureStatus] = useState("idle");

  useEffect(() => {
    if (emailCaptureStatus === "success") {
      navigate(`/login/reset-password/${email}`);
    } else if (emailCaptureStatus === "failed") {
      toast.error("failed to verify email");
    }
    // eslint-disable-next-line
  }, [emailCaptureStatus]);

  const handleBlur = () => {
    setErrors("");
  };

  const handleChange = (e) => {
    setErrors("");
    setEmail(e.target.value);
  };

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    if (email) {
      setEmailCaptureStatus("pending");

      validateEmail({ email: emailRef.current, email: email }).then(
        (response) => {
          if (response?.data?.statusCode === 200) {
            navigate(`/login/reset-password/${email}`);

            setEmailCaptureStatus("success");
          } else {
            setErrors("Sorry Invalid Email");
            setEmailCaptureStatus("failed");
          }
        }
      );
    } else {
      setErrors("*Email cannot be empty");
    }
  };

  return {
    email,
    errors,
    emailCaptureStatus,
    handleChange,
    handleSubmit,
    handleBlur,
    handleLoginClick,
  };
};

export default useCaptureEmail;
