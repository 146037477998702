import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();

    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("task_type")}&${getParams(
          "user_type_sort"
        )}${getParams("start_date")}${getParams("search")}&${getParams(
          "end_date"
        )}&${getParams("sort_by")}&sort=${
          params?.sort_order || "desc"
        }&page_size=${params?.page_size || "10"}&page_no=${params?.page_no || 1}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const approvedRequests = createApi({
  reducerPath: "ApprovedRequestsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["ApprovedRequests"],
  endpoints: (builder) => ({
    getApprovedRequestsData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `vendor/api/approved-rewards`,
      }),
      providesTags: ["ApprovedRequests"],
      keepUnusedDataFor:0,
    }),
    updateTableFieldsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `vendor/api/panel-listing`,
      }),
      invalidatesTags: ["ApprovedRequests"],
    }),
  }),
});

export const {
  useGetApprovedRequestsDataQuery,
  useUpdateTableFieldsDataMutation,
} = approvedRequests;
