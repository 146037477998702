import LOGO from "./images/header/logo.svg";
import USER from "./images/header/avatar.png";
import HEADERLOGO from "./images/header/logo.svg";
import IMAGE_APPEARANCE from "./images/image-appearance.png";
import THEME_BLACK from "./images/black.png";
import THEME_LIGHT from "./images/light.png";
import THEME_CLOSED from "./images/closed.png";
import THEME_COMPACT from "./images/compact.png";
import THEME_DARK from "./images/dark.png";
import THEME_DETAILED from "./images/detailed.png";
import THEME_EXPANDED from "./images/expanded.png";
import NO_DATA from "./images/no_data.svg";
import EMPTY_DATA from "./images/empty_data.png";
// import HEADERLOGODARK from "./images/Logo.png";
import FEMALEPROFILE from "./images/female.png";
import MALEPROFILE from "./images/male.png";
import GENDER_IMAGE from "./images/gender_image.png";
import AUTH_CIRCLES from "./images/login-circle.svg";
import AUTH_MAN from "./images/auth-man.png";
import carret from "./images/PrivacyPolicy/carret.png";
import PrivacyLogo from "./images/PrivacyPolicy/logo.png";

const Assets = {
  LOGO,
  USER,
  HEADERLOGO,
  IMAGE_APPEARANCE,
  THEME_BLACK,
  THEME_LIGHT,
  THEME_CLOSED,
  THEME_COMPACT,
  THEME_DARK,
  THEME_DETAILED,
  THEME_EXPANDED,
  NO_DATA,
  EMPTY_DATA,
  // HEADERLOGODARK,
  FEMALEPROFILE,
  MALEPROFILE,
  GENDER_IMAGE,
  AUTH_CIRCLES,
  AUTH_MAN,
  carret,
  PrivacyLogo,
};

export default Assets;
