import React from "react";
import { PrivacyHeader } from "./PrivacyHeader";
import PrivacyFooter from "./PrivacyFooter";
import Style from "./privacyPolicy.module.scss";
const PrivacyPolicy = () => {
  return (
    <>
      <PrivacyHeader />
      <main class={`${Style.section}`}>
        <section class="privacy-policy">
          <div class="container">
            <div class="content">
              <h2 class="title">Privacy Policy</h2>
              <p>
                This privacy policy has been compiled to better serve those who
                are concerned with how their ‘Personally Identifiable
                Information’ (PII) is being used online. Please read our privacy
                policy carefully to get a clear understanding of how we collect,
                use, protect or otherwise handle your Personally Identifiable
                Information in accordance with our app.
              </p>

              <ul>
                <li>
                  When registering on our app, as appropriate, you may be asked
                  to enter your name, email address, mailing address, phone
                  number or other details to help you with your experience
                </li>
                <li>
                  We collect information from you when you register on our app,
                  subscribe to a newsletter, fill out a form or enter
                  information on our app.
                </li>
                <li>
                  Our app is scanned on a regular basis for security holes and
                  known vulnerabilities in order to make our app as safe as
                  possible. We only provide articles and information. We never
                  ask for personal or private information like credit card
                  numbers.
                </li>
                <li>
                  We do not sell, trade, or otherwise transfer to outside
                  parties your Personally Identifiable Information.
                </li>
                <li>
                  We have not enabled Google AdSense on our app but we may do so
                  in the future.
                </li>
                <li>
                  If there are any questions regarding this privacy policy, you
                  may contact us using the address provided below.
                </li>
              </ul>
              <p>
                <a href="mailto:bapindia.pvt.ltd@gmail.com">
                  bapindia.pvt.ltd@gmail.com
                </a>
              </p>
            </div>
          </div>
        </section>
      </main>
      <PrivacyFooter />
    </>
  );
};

export default PrivacyPolicy;
