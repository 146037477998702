import React from "react";
import {
  BrowserRouter,
  Route,
  Routes as BrowserRoutes,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Index from "../pages";
import AuthModule from "../components/Auth";
import Login from "../components/Auth/Login";
// import ResetPassword from "../components/Auth/ResetPassword";
// import OtpVerification from "../components/Auth/OtpVerification";
// import useTheme from "../components/Configure/Appearance/useTheme";

import Dashboard from "../components/Dashboard";
import DashboardListing from "../components/Dashboard/DashboardListing";
import UserVerification from "../components/UserVerification";
import PendingRequests from "../components/PendingRequests";
import ApprovedRequests from "../components/ApprovedRequests";
import PriceUpdation from "../components/PriceUpdation";
import Payouts from "../components/Payouts";
import UserVerificationMain from "../components/UserVerification/userVerificationMain";
import PendingRequestsListing from "../components/PendingRequests/PendingRequestsListing";
import ApprovedRequestsListing from "../components/ApprovedRequests/ApprovedRequestsListing";
import PriceUpdationListing from "../components/PriceUpdation/PriceUpdationListing";
import PayoutsListing from "../components/Payouts/PayoutsListing";
import OfferVerificationMain from "../components/UserVerification/OfferVerificationMain";
import CaptureEmail from "../components/Auth/CaptureEmailForm";
import ResetPassword from "../components/Auth/ResetPassword";
import PrivacyPolicy from "../components/PrivacyPolicy";

const Routes = () => {
  const { userOtpVerified } = useSelector((state) => state.userVerification);
  return (
    <BrowserRouter>
      <BrowserRoutes>
        <Route path="/" element={<Index />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="dashboard" element={<Dashboard />}>
          <Route path="" element={<DashboardListing />} />
        </Route>
        <Route path="user-verification" element={<UserVerification />}>
          <Route path="" element={<UserVerificationMain />} />

          {userOtpVerified === 200 ? (
            <Route
              path="offer-verification"
              element={<OfferVerificationMain />}
            />
          ) : null}
        </Route>
        <Route path="pending-requests" element={<PendingRequests />}>
          <Route path="" element={<PendingRequestsListing />} />
        </Route>
        <Route path="approved-requests" element={<ApprovedRequests />}>
          <Route path="" element={<ApprovedRequestsListing />} />
        </Route>
        <Route path="price-updation" element={<PriceUpdation />}>
          <Route path="" element={<PriceUpdationListing />} />
        </Route>
        <Route path="payouts" element={<Payouts />}>
          <Route path="" element={<PayoutsListing />} />
        </Route>

        <Route path="/login" element={<AuthModule />}>
          <Route path="" element={<Login />}></Route>
          <Route
            path="forgot-password/capture-email"
            element={<CaptureEmail />}
          ></Route>
          {/* <Route path="verify-otp" element={<OtpVerification />}></Route> */}
          <Route
            path="reset-password/:email"
            element={<ResetPassword />}
          ></Route>
        </Route>

        <Route path={"/*"} element={<Navigate to={"/"} />} />
      </BrowserRoutes>
    </BrowserRouter>
  );
};

export default Routes;
