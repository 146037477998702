import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";

const useMenus = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  
  // const handleSetSlug = (slug) => {
  //   localStorage?.setItem("slug", slug);
  // };
  const drawerMenu = [
    {
      title: "Dashboard",
      label: "Dashboard",
      link: " /dashboard",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x3">bar_chart_4_bars</span>,
      active:
        checkIfActiveRoute("/dashboard", true) 
    },

    {
      title: "User Verification",
      label: "User Verification",
      link: "/user-verification",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x3">group</span>,
      active:
        checkIfActiveRoute("/user-verification", true) 
    },

    {
      title: "Pending Requests",
      label: "Pending Requests",
      link: "/pending-requests",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x3">contract</span>,
      active:
        checkIfActiveRoute("/pending-requests", true)
    },
    {
      title: "Approved Requests",
      label: "Approved Requests",
      link: "/approved-requests",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x3">contract</span>,
      active:
        checkIfActiveRoute("/approved-requests", true)
    },
    {
      title: "Price Updation",
      label: "Price Updation",
      link: "/price-updation",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x3">call_log</span>,
      active:
        checkIfActiveRoute("/price-updation", true)
    },
    {
      title: "Payouts",
      label: "Payouts",
      link: "/payouts",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x3">account_balance_wallet</span>,
      active:
        checkIfActiveRoute("/payouts", true)
    },   
  ];
  

  return { drawerMenu: drawerMenu };
};

export default useMenus;
