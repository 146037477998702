import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();

    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("user_id")}&${getParams("price")}&${getParams(
          "sort_by"
        )}&sort=${params?.sort_order || "desc"}&page_size=${
          params?.page_size || "10"
        }&page_no=${params?.page || 1}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const userVerification = createApi({
  reducerPath: "UserVerificationApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["UserDetails"],
  endpoints: (builder) => ({
    getUserDetails: builder.query({
      query: (value) => ({
        method: "get",
        endpoint: `vendor/api/get-verified-user-detail/${value}`,
      }),
      providesTags: ["UserDetails"],
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useGetUserDetailsQuery } = userVerification;
