import React from "react";
import {
  HeadingGroup,
  Button,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import useUserVerificationMain from "./useUserVerificationMain";
import Select from "react-select";
import UserOtpVerificationModal from "./UserOtpVerificationModal";
import Style from "../userVerification.module.scss";

const UserVerificationMain = () => {
  const {
    formik,
    selectedUserDetails,
    optionsData,
    userDataFetched,
    seconds,
    verifyOtpModal,
    setCloseVerifyOtpModal,
    handleSubmitOtp,
    handleUserNumber,
    handleInputChange,
    setSeconds,
    setVerifyOtpModal,
  } = useUserVerificationMain();

  const noOptionsMessage = () =>
    optionsData?.data?.length > 0
      ? "No matching options"
      : "Search for a user phonenumber...";

  return (
    <>
      <HeadingGroup title={"User Verification"} extraClassName={`pro-mb-5`} />

      <div className="col-lg-9">
        <div className={`${Style.container}`}>
          <label htmlFor="phone" className="pro-font-sm pro-mb-5 pro-fw-medium">
            Search User by Mobile Number
          </label>
          <div className="row gx-4">
            <div className="col-3">
              <Select
                id="phone"
                placeholder="Search for a user..."
                name="phone"
                noOptionsMessage={noOptionsMessage}
                isClearable={true}
                options={optionsData?.map((item) => ({
                  label: item.mobile_number,
                  value: item,
                }))}
                value={optionsData?.find(
                  (item) => item.mobile_number === formik?.values?.phone
                )}
                onInputChange={(value) => handleInputChange(value)}
                className={`pro-input multi-select lg ${
                  formik?.errors?.phone && formik?.touched?.phone ? "error" : ""
                }`}
                classNamePrefix="pro-input"
                onBlur={formik.handleBlur("phone")}
                onChange={(selectedOption) =>
                  handleUserNumber(selectedOption?.value)
                }
              />
            </div>
            <div className={`col`}>
              <Button
                className={`pro-btn-primary lg `}
                type="submit"
                onClick={formik.handleSubmit}
                disabled={userDataFetched}
              >
                Search
              </Button>
            </div>
          </div>
        </div>
      </div>

      {userDataFetched && (
        <div className="col-4">
          <div className={`${Style.container} ${Style.bg} pro-p-5 pro-mt-4`}>
            <div
              className={`pro-d-flex pro-justify-between pro-items-center ${Style.lg_spacer}`}
            >
              <h6 className="pro-ttl h6 pro-mb-0">User Details</h6>
              <p className="pro-mb-0 pro-fw-bold">
                {selectedUserDetails[0]?.user_unique_id}
              </p>
            </div>
            <div className="row row-cols-3">
              <div>
                <label
                  className={`pro-mb-3 pro-text-capitalize ${Style.label}`}
                >
                  name
                </label>
                <h6 className="pro-mb-0">
                  {`${selectedUserDetails[0]?.first_name} ${
                    selectedUserDetails[0]?.last_name ?? ""
                  }`}
                </h6>
              </div>
              <div>
                <label
                  className={`pro-mb-3 pro-text-capitalize ${Style.label}`}
                >
                  Mobile Number
                </label>
                <h6 className="pro-mb-0">
                  {selectedUserDetails[0]?.mobile_number}
                </h6>
              </div>

              <div className={`pro-d-flex pro-justify-end pro-items-end`}>
                <Button
                  className={`pro-btn-primary lg pro-ms-3 `}
                  type="submit"
                  onClick={(e) => handleSubmitOtp(e)}
                  // disabled={!formik?.values?.selected_phone}
                >
                  Send OTP
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      <ModalLayout
        centered={true}
        show={verifyOtpModal}
        title={"User Details"}
        handleClose={setCloseVerifyOtpModal}
      >
        <div className="pro-m-5">
          <UserOtpVerificationModal
            handleSubmitOtp={handleSubmitOtp}
            seconds={seconds}
            setSeconds={setSeconds}
            setVerifyOtpModal={setVerifyOtpModal}
          />

          {true && <span className="error-message">{true}</span>}
        </div>
      </ModalLayout>
    </>
  );
};

export default UserVerificationMain;
