import { combineReducers } from "@reduxjs/toolkit";
import globalSlice from "./slices/Global";

import { dashboard } from "./queries/Dashboard";
import { pendingRequests } from "./queries/pendingRequests";
import PendingRequestsSlice from "./slices/PendingRequests/PendingRequestsSlice";
import { approvedRequests } from "./queries/approvedRequests";
import ApprovedRequestsSlice from "./slices/ApprovedRequests/ApprovedRequestsSlice";
import { payouts } from './queries/payouts';
import PayoutsSlice from "./slices/Payouts/PayoutsSlice";
import { priceUpdation } from "./queries/priceUpdation";
import PriceUpdationSlice from "./slices/PriceUpdation/PriceUpdationSlice";
import { userVerification } from "./queries/userVerification";
import UserVerificationSlice from "./slices/UserVerification/UserVerificationSlice";

const appReducer = combineReducers({

  [dashboard.reducerPath]: dashboard.reducer,
  [pendingRequests.reducerPath]: pendingRequests.reducer,
  [approvedRequests.reducerPath]: approvedRequests.reducer,
  [payouts.reducerPath]: payouts.reducer,
  [priceUpdation.reducerPath]: priceUpdation.reducer,
  [userVerification.reducerPath]:userVerification.reducer,
  global: globalSlice,
  pendingRequests: PendingRequestsSlice,
  approvedRequests: ApprovedRequestsSlice,
  payouts: PayoutsSlice,
  priceUpdation: PriceUpdationSlice,
  userVerification:UserVerificationSlice,
});

export default appReducer;
