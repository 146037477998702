import { useDispatch, useSelector } from "react-redux";
import {
  useGetPayoutsDataQuery,
  useUpdateTableFieldsDataMutation,
} from "../../../store/queries/payouts";
import { updateConfig } from "../../../store/slices/Payouts/PayoutsSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { useState } from "react";
import {
  getFormatedDate,
  getFormatedDateRange,
} from "../../../utils/functions/table";
import { addDays } from "date-fns";
import { subDays } from "date-fns";

const usePayoutListing = ({ dashboard }) => {
  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.payouts);
  const { showEditModal } = useSelector((state) => state.global);
  const [updateTableFields] = useUpdateTableFieldsDataMutation();
  let actionOptions = [{ label: "Edit Columns", value: 0 }];

  const [date, setDate] = useState({
    startDate: menuState.start,
    endDate: menuState.end,
    key: "selection",
  });

  const {
    data: mainData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetPayoutsDataQuery({
    sort_by: menuState.sortBy,
    sort_order: menuState.sortOrder,
    search: menuState.search,
    page_size: dashboard ? 10 : menuState.currentPageSize,
    page_no: dashboard ? 1 : menuState.currentPage,
    start_date: getFormatedDate(menuState?.start),
    end_date: getFormatedDate(menuState?.end),
    // is_active: menuState.is_active
  });

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: currentPageSize }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = currentPageSize;
        state.currentPage = 1;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      first_name: (field, data) => (
        <p className="pro-mb-0">{data["user_id"]?.[field]}</p>
      ),
      payout_created_date: (field, data) => getFormatedDateRange(data?.[field]),
      user_unique_id: (field, data) => (
        <p className="pro-mb-0">{data["user_id"]?.[field]}</p>
      ),
      transferred_status: (field, data) => {
        return data[field] === "PENDING" ? (
          <span className="pro-badge badge-warning pro-d-inline-flex pro-items-center">
            <svg
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.8571 6C12.8571 9.31371 10.1708 12 6.85712 12C3.54341 12 0.857117 9.31371 0.857117 6C0.857117 2.68629 3.54341 0 6.85712 0C10.1708 0 12.8571 2.68629 12.8571 6ZM5.85712 3C5.85712 2.44772 6.30483 2 6.85712 2C7.4094 2 7.85712 2.44772 7.85712 3V6C7.85712 6.55228 7.4094 7 6.85712 7C6.30483 7 5.85712 6.55228 5.85712 6V3ZM6.85712 8C6.30483 8 5.85712 8.44771 5.85712 9C5.85712 9.55229 6.30483 10 6.85712 10C7.4094 10 7.85712 9.55229 7.85712 9C7.85712 8.44771 7.4094 8 6.85712 8Z"
                fill="#AB6100"
              />
            </svg>
            <span>Pending</span>
          </span>
        ) : data[field] === "FAILED" ? (
          <span
            style={{ width: "85px" }}
            className="pro-badge badge-danger pro-d-inline-flex pro-items-center"
          >
            <svg
              width="12"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6 12.2316C9.31371 12.2316 12 9.54528 12 6.23157C12 2.91786 9.31371 0.231567 6 0.231567C2.68629 0.231567 0 2.91786 0 6.23157C0 9.54528 2.68629 12.2316 6 12.2316ZM3 5.23157C2.44772 5.23157 2 5.67928 2 6.23157C2 6.78385 2.44772 7.23157 3 7.23157H9C9.55229 7.23157 10 6.78385 10 6.23157C10 5.67928 9.55229 5.23157 9 5.23157H3Z"
                fill="#DD2B0E"
              />
            </svg>
            <span>Failed</span>
          </span>
        ) : data[field] === "SUCCESS" ? (
          <span
            style={{ width: "85px" }}
            className="pro-badge badge-success pro-d-inline-flex pro-items-center"
          >
            <svg
              width="12"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6 12.3928C9.31371 12.3928 12 9.70647 12 6.39276C12 3.07905 9.31371 0.392761 6 0.392761C2.68629 0.392761 0 3.07905 0 6.39276C0 9.70647 2.68629 12.3928 6 12.3928ZM8.57617 5.3729C8.84134 5.05469 8.79835 4.58177 8.48014 4.3166C8.16193 4.05142 7.68901 4.09442 7.42383 4.41262L5.4496 6.7817L4.53033 5.86243C4.23744 5.56954 3.76256 5.56954 3.46967 5.86243C3.17678 6.15532 3.17678 6.6302 3.46967 6.92309L4.96967 8.42309C5.11867 8.57209 5.32348 8.65154 5.53399 8.64199C5.74449 8.63244 5.94127 8.53478 6.07617 8.3729L8.57617 5.3729Z"
                fill="#108548"
              />
            </svg>
            <span>Complete</span>
          </span>
        ) : data[field] === "REVERSED" ? (
          <span
            style={{ width: "85px" }}
            className="pro-badge badge-warning pro-d-inline-flex pro-items-center"
          >
            <svg
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.8571 6C12.8571 9.31371 10.1708 12 6.85712 12C3.54341 12 0.857117 9.31371 0.857117 6C0.857117 2.68629 3.54341 0 6.85712 0C10.1708 0 12.8571 2.68629 12.8571 6ZM5.85712 3C5.85712 2.44772 6.30483 2 6.85712 2C7.4094 2 7.85712 2.44772 7.85712 3V6C7.85712 6.55228 7.4094 7 6.85712 7C6.30483 7 5.85712 6.55228 5.85712 6V3ZM6.85712 8C6.30483 8 5.85712 8.44771 5.85712 9C5.85712 9.55229 6.30483 10 6.85712 10C7.4094 10 7.85712 9.55229 7.85712 9C7.85712 8.44771 7.4094 8 6.85712 8Z"
                fill="#AB6100"
              />
            </svg>
            <span>Reversed</span>
          </span>
        ) : data[field] === "REJECTED" ? (
          <span
            style={{ width: "85px" }}
            className="pro-badge badge-danger pro-d-inline-flex pro-items-center"
          >
            <svg
              width="12"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6 12.2316C9.31371 12.2316 12 9.54528 12 6.23157C12 2.91786 9.31371 0.231567 6 0.231567C2.68629 0.231567 0 2.91786 0 6.23157C0 9.54528 2.68629 12.2316 6 12.2316ZM3 5.23157C2.44772 5.23157 2 5.67928 2 6.23157C2 6.78385 2.44772 7.23157 3 7.23157H9C9.55229 7.23157 10 6.78385 10 6.23157C10 5.67928 9.55229 5.23157 9 5.23157H3Z"
                fill="#DD2B0E"
              />
            </svg>
            <span>Rejected</span>
          </span>
        ) : (
          ""
        );
      },
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.start = startDate;
        state.end = endDate;
      })
    );
  };

  const handleSort = (label) => {
    if (menuState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleClearClick = () => {
    setDate({
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.selctedId = "";
        state.is_active = null;
        state.start = subDays(new Date(), 30);
        state.end = new Date();
      })
    );
  };

  return {
    mainData,
    menuState,
    isFetching,
    showEditModal,
    paginationOptions,
    currentPage: menuState?.currentPage,
    actionOptions,
    date,
    isLoading,
    handleClearClick,
    handleSearch,
    updateTableFields,
    handleActionChange,
    handleSort,
    handlePagination,
    handlePageSize,
    closeEditModal,
    getRow,
    refetch,
    handleDateRangeChange,
  };
};

export default usePayoutListing;
