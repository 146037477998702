import { Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useRewardSection from "./useRewardSection";

const RewardSection = ({
  formik,
  itemIndex,
  data,
  handleChangeReward,
  handleChangeQuantity,
}) => {
  const { handleRemoveField } = useRewardSection({ formik });

  const filteredRewards = (data || []).filter((item) => {
    const rewards = formik.values.rewards || [];
    return !rewards.some(
      (reward) => reward["reward"]?.reward_id === item.reward_id
    );
  });

  return (
    <>
      <div className="row gx-2 pro-mb-2 ">
        <div className="col-8">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Reward
          </label>
          <Select
            id="reward"
            placeholder={"Select"}
            className={`pro-input lg  ${
              formik.touched.rewards?.[itemIndex]?.reward &&
              formik.errors.rewards?.[itemIndex]?.reward &&
              " error"
            }`}
            classNamePrefix="pro-input"
            isClearable={true}
            options={filteredRewards}
            getOptionValue={(option) => option?.reward_id}
            getOptionLabel={(option) => option?.reward_name}
            value={data?.filter(
              (m) =>
                formik?.values?.rewards?.[itemIndex]?.reward?.reward_id ===
                m?.reward_id
            )}
            onBlur={formik.handleBlur(`rewards?.${itemIndex}?.reward`)}
            // onBlur={() => {
            //   formik.setFieldTouched(`rewards?.${itemIndex}?.reward`);
            // }}
            onChange={(value) => {
              handleChangeReward(value, itemIndex);
              // formik.setFieldValue("reward", value?.reward_id || null);
              // formik.setFieldValue("price", value?.price);
              // formik.setFieldValue("redeemed_points", value?.points_to_redeem);
              // formik?.setFieldValue("cash", value?.remaining_cash);
            }}
          />
          {formik.touched.rewards?.[itemIndex]?.reward &&
            formik.errors.rewards?.[itemIndex]?.reward && (
              <span className="error-text">
                {formik.errors.rewards?.[itemIndex]?.reward}
              </span>
            )}
        </div>
        <div className="col-3">
          <Input
            label="Quantity"
            type="text"
            id={`rewards.${itemIndex}.reward_quantity`}
            name={`rewards.${itemIndex}.reward_quantity`}
            className={`pro-input lg ${
              formik.errors.rewards?.[itemIndex]?.reward_quantity &&
              formik.touched.rewards?.[itemIndex]?.reward_quantity &&
              "error"
            }`}
            {...formik.getFieldProps(`rewards.${itemIndex}.reward_quantity`)}
            error={
              formik.errors.rewards?.[itemIndex]?.reward_quantity &&
              formik.touched.rewards?.[itemIndex]?.reward_quantity
            }
            onChange={(e) => handleChangeQuantity(e, itemIndex)}
            errorMessage={formik.errors.rewards?.[itemIndex]?.reward_quantity}
          />
        </div>
        {formik?.values?.rewards?.length > 1 && (
          <div className="col-1 pro-mt-5 pro-pt-3">
            <button className="pro-btn pro-p-0">
              <span
                onClick={() => handleRemoveField(itemIndex)}
                className="material-symbols-outlined x5"
              >
                close
              </span>
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default RewardSection;
