import React from "react";
import Select from "react-select";
import {
  Button,
  ModalLayout,
  HeadingGroup,
} from "@wac-ui-dashboard/wac_component_library";
import useOfferVerificationMain from "./useOfferVerificationMain";
import OfferOtpVerificationModal from "./OfferOtpVerificationModal";
import Style from "../userVerification.module.scss";

const OfferVerificationMain = () => {
  const {
    selectedUserDetails,
    formik,
    userData,
    showOfferOtp,
    verifyVendorOtpModal,
    seconds,
    setCloseVerifyVendorOtpModal,
    handleSendOfferOtp,
    handleChangeOffer,
    setSeconds,
  } = useOfferVerificationMain();
  return (
    <div>
      <HeadingGroup title={"User Details"} extraClassName="pro-mb-5" />
      <div className={`${Style.container} ${Style.bg}`}>
        <h5 className="pro-mb-5">General Information</h5>
        <div className="row ">
          <div className="col-lg-3">
            <label className={`${Style.label} pro-mb-2`}>Name</label>
            <h6 className="pro-ttl h6 pro-mb-0">
              {`${selectedUserDetails[0]?.first_name} ${
                selectedUserDetails[0]?.last_name ?? ""
              }`}
            </h6>
          </div>
          <div className="col-lg-3">
            <label className={`${Style.label} pro-mb-2`}>Mobile Number</label>
            <h6 className="pro-ttl h6 pro-mb-0">
              {selectedUserDetails[0]?.mobile_number}
            </h6>
          </div>
          <div className="col-lg-3">
            <label
              htmlFor=""
              className={`pro-mb-2 ${`${Style.label} pro-mb-2`}`}
            >
              Offer
            </label>
            <Select
              id="offer"
              placeholder={"Select"}
              className={`pro-input lg  ${
                formik.touched.offer && formik.errors.offer && " error"
              }`}
              classNamePrefix="pro-input"
              isDisabled={showOfferOtp}
              options={userData?.data?.offers}
              getOptionValue={(option) => option?.offer_id}
              getOptionLabel={(option) => option?.name}
              value={userData?.data?.offers?.filter(
                (m) => formik?.values?.offer === m?.offer_id
              )}
              onBlur={formik.handleBlur("offer")}
              onChange={(value) => {
                handleChangeOffer(value);
                // formik.setFieldValue("offer", value?.offer_id || null)
                // setOfferType(value?.id);
              }}
            />
            {formik.touched.offer &&
              formik.errors.offer &&
              !formik?.values?.offer && (
                <span className="error-text">{formik.errors.offer}</span>
              )}{" "}
          </div>
          {/* <div className="col-lg-3">
            <label className={`${Style.label} pro-mb-2`}>Series</label>
            <Select
              id="series"
              name="series"
              placeholder={"Select"}
              className={`pro-input lg  ${
                formik?.errors?.series && formik?.touched?.series && " error"
              }`}
              classNamePrefix="pro-input"
              options={userData?.data?.series?.filter(
                (item) => item.offer_id === formik?.values?.offer
              )}
              value={userData?.data?.series?.filter(
                (m) => formik.values.series === m?.series_id
              )}
              getOptionLabel={(option) => option?.series_name}
              getOptionValue={(option) => option?.series_id}
              onBlur={formik.handleBlur("series")}
              onChange={(value) => {
                handleChangeSeries(value);
                // formik.setFieldValue("series", value?.series_id || null) && formik.values.series !== "" && handleSubmit()
              }}
              menuPlacement="auto"
            />
            {formik?.errors?.series &&
              formik.touched.series &&
              !formik?.values?.series && (
                <span className="error-text">{formik?.errors?.series}</span>
              )}
          </div> */}
        </div>
        {/* field to show the points  */}

        {formik?.values?.total_point && (
          <div className="pro-pt-5 pro-mt-5">
            <div className="row">
              <div className="col-lg-3">
                <label className={`${Style.label} pro-mb-2`}>
                  Total Points
                </label>
                <h6 className="pro-ttl h6 pro-mb-0">
                  {formik?.values?.total_point}
                </h6>
              </div>
              <div className="col-lg-3">
                <label className={`${Style.label} pro-mb-2`}>
                  Balance Points
                </label>
                <h6 className="pro-ttl h6 pro-mb-0">
                  {formik?.values?.entered_point === ""
                    ? formik?.values?.balance_points
                    : formik?.values?.balance_points -
                      formik?.values?.entered_point}
                </h6>
              </div>
              <div className="col-lg-3">
                <label htmlFor="" className={`pro-mb-2 ${Style.label}`}>
                  Points to Redeem
                </label>
                <input
                  name="entered_point"
                  value={formik?.values?.entered_point}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={!formik?.values?.offer || showOfferOtp}
                  type="text"
                  className={`pro-input lg ${
                    formik?.touched?.entered_point &&
                    formik?.errors?.entered_point &&
                    "error"
                  }`}
                />
                {formik?.touched?.entered_point &&
                  formik?.errors?.entered_point && (
                    <span className="error-text">
                      {formik?.errors?.entered_point}
                    </span>
                  )}
              </div>
              <div className="col-lg-3 pro-d-flex pro-items-start pro-pt-5">
                <Button
                  className={`pro-btn-primary pro-mt-3 pro-px-5 lg`}
                  disabled={showOfferOtp}
                  onClick={() => formik?.handleSubmit()}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        )}

        {/* Sending Offer verification OTP */}
        {showOfferOtp && (
          <div className="pro-mt-6 pro-border-top pro-pt-5">
            <h6 className="pro-mb-5">Offer verification</h6>
            <div className="pro-d-flex pro-gap-5 pro-items-center">
              <p className="pro-mb-0">Initiate Offer verification Process </p>
              <Button
                // disabled ={}
                className={`pro-btn-primary pro-ms-3`}
                onClick={() => handleSendOfferOtp()}
              >
                Send OTP
              </Button>
            </div>
          </div>
        )}
      </div>

      <ModalLayout
        centered={true}
        show={verifyVendorOtpModal}
        title={"Offer Details"}
        handleClose={setCloseVerifyVendorOtpModal}
        backdrop="static"
      >
        <div className="pro-m-5">
          <OfferOtpVerificationModal
            seconds={seconds}
            setSeconds={setSeconds}
            handleSendOfferOtp={handleSendOfferOtp}
            setCloseVerifyVendorOtpModal={setCloseVerifyVendorOtpModal}
          />

          {true && <span className="error-message">{true}</span>}
        </div>
      </ModalLayout>
    </div>
  );
};

export default OfferVerificationMain;
