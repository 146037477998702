import { getAxiosInstance } from "../../api";


export const getUserPhoneNumbers = async ({ search }) => {

     const api = await getAxiosInstance();
     try {
          const response = await api.get(`vendor/api/get-autocomplete-users?query=${search}`);
          return response;
     } catch (error) {
          return error.response.data;
     }
};

export const getUserDetails = async (value) => {
     const api = await getAxiosInstance();
     try {
          const response = await api.get(`vendor/api/get-user-details/${value}`);
          return response;
     } catch (error) {
          return error.response.data;
     }
};

export const getUserOtp = async (value) => {
     const api = await getAxiosInstance();
     try {
          const response = await api.post(`vendor/api/send-verification-otp-user`,value);
          return response;
     } catch (error) {
          return error.response.data;
     }
};

export const verifyUserOtp = async (value) => {
     const api = await getAxiosInstance();
     try {
          const response = await api.post(`vendor/api/verify-verification-otp-user`,value);
          return response;
     } catch (error) {
          return error.response.data;
     }
};

export const getOfferPoints = async (value) => {
     const api = await getAxiosInstance();
     try {
          const response = await api.post(`/vendor/api/change-offer`,value);
          return response;
     } catch (error) {
          return error.response.data;
     }
};

export const checkUserPoint = async (value) => {
     const api = await getAxiosInstance();
     try {
          const response = await api.post(`vendor/api/check-user-point`,value);
          return response;
     } catch (error) {
          return error.response.data;
     }
};

export const getVendorOtp = async (value) => {
     const api = await getAxiosInstance();
     try {
          const response = await api.post(`vendor/api/send-verification-otp-vendor`,value);
          return response;
     } catch (error) {
          return error.response.data;
     }
};








